import { useState, useEffect } from 'react';
import '../styles/SearchBar.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from './SearchBar';
import TrashBtn from './TrashBtn';
import CleanBtn from './CleanBtn';
import PhotoWindow from './PhotoWindow';
import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import pluralize from 'pluralize';
import shuffle from 'shuffle-array';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/config';

const client = sanityClient({
  projectId: 'fhf9837e',
  dataset: 'production',
  apiVersion: '2022-07-19',
  useCdn: true,
});
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const Home = (props) => {
  const [load, setLoad] = useState(props.load);
  const [imgURLs, setImgURLs] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState(null);
  const [formData, setFormData] = useState('');
  const [searchParams] = useSearchParams();
  const [emptiedOnce, setEmptiedOnce] = useState(false);
  const [shouldClean, setShouldClean] = useState(false);
  const [shouldScatter, setShouldScatter] = useState(false);
  const [isClean, setIsClean] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsClean(false);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const getRandom = async () => {
      const fetchStr = `*[_type=='sanity.imageAsset']{
        _id
      }`;
      return client.fetch(fetchStr).then((res) => {
        let urls = [];
        res.forEach((imgAsset) => {
          let url = urlFor(imgAsset).url();
          if (!urls.includes(url)) {
            urls.push(url);
          }
        });
        shuffle(urls);
        return urls;
      });
    };

    const getPerfectMatches = (query, prefix = '') => {
      const fetchStr = `*[_type == "media.tag" && name.current == "${prefix}${query.toLowerCase()}"]{
        "imgAssetsWithTag": *[_type=='sanity.imageAsset' && references(^._id)]
      }`;
      return client.fetch(fetchStr).then((res) => {
        let urls = [];
        res.forEach((tag) => {
          tag.imgAssetsWithTag.forEach((imgAsset) => {
            let url = urlFor(imgAsset).url();
            if (!urls.includes(url)) {
              urls.push(url);
            }
          });
        });
        shuffle(urls);
        return urls;
      });
    };

    const getPerfectManualMatches = (query) => {
      return getPerfectMatches(query);
    };

    const getPerfectAIMatches = (query) => {
      return getPerfectMatches(query, '[ai generated] ');
    };

    const getPerfectAltMatches = (query) => {
      const altQuery = pluralize.isPlural(query)
        ? pluralize.singular(query)
        : pluralize.plural(query);

      return getPerfectMatches(altQuery);
    };

    const getRankedMatches = async (subQueries) => {
      // Rank imageAssets based on # of matching subqueries / tags
      const matchCount = {};
      for (let i = 0; i < subQueries.length; i++) {
        const subQuery = subQueries[i];
        const subQueryFetchStr = `*[_type == "media.tag" && name.current match "${subQuery.toLowerCase()}"]{
          "imgAssetsWithTag": *[_type=='sanity.imageAsset' && references(^._id)]
          }`;
        // Match tags
        const res = await client.fetch(subQueryFetchStr);
        res.forEach((tag) => {
          // Add img assets with tag to dict and increment their count
          tag.imgAssetsWithTag.forEach((imgAsset) => {
            const url = urlFor(imgAsset).url();
            if (matchCount[url] === undefined) {
              matchCount[url] = 0;
            }
            matchCount[url] += 1;
          });
        });
      }

      // Bucket by match count and shuffle
      const buckets = {};
      Object.keys(matchCount).forEach((url) => {
        const count = matchCount[url];
        if (buckets[count] === undefined) {
          buckets[count] = [url];
        } else {
          buckets[count].push(url);
        }
      });
      Object.keys(buckets).forEach((count) => {
        shuffle(buckets[count]);
      });

      // Add back to array and sort
      const tuples = [];
      Object.keys(buckets).forEach((count) => {
        buckets[count].forEach((url) => {
          tuples.push([url, count]);
        });
      });
      tuples.sort((first, second) => {
        return second[1] - first[1];
      });
      const urls = tuples.map((arrEl) => arrEl[0]);

      return urls;
    };

    const getSubMatches = async (query) => {
      // Test any substring of search
      const subQueries = query.split(' ');
      return getRankedMatches(subQueries);
    };

    const getSubAltMatches = async (query) => {
      // Test any substring of search
      const subQueries = query.split(' ');
      const subAltQueries = subQueries.map((subQuery) =>
        pluralize.isPlural(subQuery)
          ? pluralize.singular(subQuery)
          : pluralize.plural(subQuery)
      );
      return getRankedMatches(subAltQueries);
    };

    const getQuery = async (query) => {
      const max = 30;

      let urls;
      if (query.length === 0) {
        urls = await getRandom();
      } else {
        urls = await getPerfectManualMatches(query);

        if (urls.length < max) {
          const aiURLs = await getPerfectAIMatches(query);
          aiURLs.forEach((aiURL) => {
            if (!urls.includes(aiURL)) {
              urls.push(aiURL);
            }
          });
        }
        if (urls.length < max) {
          const altURLs = await getPerfectAltMatches(query);
          altURLs.forEach((altURL) => {
            if (!urls.includes(altURL)) {
              urls.push(altURL);
            }
          });
        }
        if (urls.length < max) {
          const subURLs = await getSubMatches(query);
          subURLs.forEach((subURL) => {
            if (!urls.includes(subURL)) {
              urls.push(subURL);
            }
          });
        }
        if (urls.length < max) {
          const subAltURLs = await getSubAltMatches(query);
          subAltURLs.forEach((subAltURL) => {
            if (!urls.includes(subAltURL)) {
              urls.push(subAltURL);
            }
          });
        }
      }

      // Remove extra
      if (urls.length > max) {
        urls.splice(max - urls.length);
      }

      // Reverse to put best matches at end
      urls.reverse();

      return urls;
    };

    const setImgURLsWithDelay = async (urls) => {
      const delay = 50;
      for (let i = 0; i < urls.length; i++) {
        setTimeout(() => {
          setImgURLs((prevImgURLs) => {
            const newURL = urls[i];
            if (!prevImgURLs.includes(newURL)) {
              return [...prevImgURLs, newURL];
            }
            return prevImgURLs;
          });
        }, delay * i);
      }
    };

    if (load) {
      setLoad(false);
      const query = searchParams.get('q');
      setFormData(query);
      getQuery(query)
        .then((urls) => {
          console.log('URLs:', urls);
          return setImgURLsWithDelay(urls);
        })
        .catch((err) => console.error('Error:', err));
    }
  }, [load, searchParams]);

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsClean(false);

    navigate(`/search?q=${formData}`);

    logEvent(analytics, 'search', {
      search_term: formData,
    });

    setImgURLs(() => []);

    setLoad(true);
  };

  const handleTrashClick = () => {
    if (imgURLs.length > 0 && !emptiedOnce) setEmptiedOnce(true);
    setImgURLs(() => []);
    setIsClean(false);
  };

  const handleClose = (imgURLToRemove) => {
    setImgURLs((prevImgURLs) => {
      const newImgURLs = prevImgURLs.filter(
        (imgURL) => imgURL !== imgURLToRemove
      );
      return newImgURLs;
    });
    if (!emptiedOnce) setEmptiedOnce(true);
    setIsClean(false);
  };

  const handleSelect = (imgURL) => {
    setCurrentlySelected(imgURL);
    setIsClean(false);
  };

  const handleCleanClick = () => {
    if (imgURLs.length > 0) {
      setShouldScatter(isClean);
      setShouldClean(!isClean);
    } else {
      setShouldScatter(false);
      setShouldClean(false);
    }
    setIsClean((prev) => !prev);
  };

  // Extra guardrail against duplicates
  const uniqueImgURLs = [...new Set(imgURLs)];
  const photoWindows = uniqueImgURLs.map((imgURL, index) => {
    return (
      <PhotoWindow
        key={imgURL}
        imgURL={imgURL}
        onClose={() => handleClose(imgURL)}
        onSelect={() => handleSelect(imgURL)}
        isSelected={imgURL === currentlySelected}
        shouldClean={shouldClean}
        index={index}
        onAnimationTriggered={() => {
          setShouldClean(false);
          setShouldScatter(false);
        }}
        total={uniqueImgURLs.length}
        shouldScatter={shouldScatter}
      />
    );
  });
  photoWindows.sort((a, b) => {
    return a.props.imgURL === currentlySelected
      ? 1
      : b.props.imgURL === currentlySelected
      ? -1
      : 0;
  });

  return (
    <div className='app'>
      <SearchBar
        onChange={handleChange}
        onSubmit={handleSubmit}
        load={load}
        imgURLs={imgURLs}
        formData={formData}
      />
      <div>{photoWindows}</div>
      <TrashBtn onClick={handleTrashClick} emptiedOnce={emptiedOnce} />
      <CleanBtn onClick={handleCleanClick} isClean={isClean} />
    </div>
  );
};

export default Home;
